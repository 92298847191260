<template>
  <div ref="modalContainer"></div>
  <a-card class="content-wrapper">
    <template #title>
      <CardHeader></CardHeader>
    </template>
    <div class="body-content">
      <a-form layout="inline">
        <a-form-item label="券包编号">
          <a-input
            v-model:value="params.couponPackageId"
            placeholder="请输入"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="优惠券编号">
          <a-input
            v-model:value="params.couponId"
            placeholder="请输入"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="名称">
          <a-input
            v-model:value="params.couponPackageName"
            placeholder="请输入"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item style="padding-bottom: 10px" label="使用情况">
          <a-select
            v-model:value="params.useState"
            notFoundContent="暂无数据"
            style="width: 200px"
            placeholder="请选择"
            allow-clear
            :options="useStates"
          >
          </a-select>
        </a-form-item>
        <a-form-item label="车牌号码">
          <a-input
            v-model:value="params.carNumber"
            placeholder="请输入"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item style="padding-bottom: 10px" label="领取时间">
          <a-range-picker
            v-model:value="params.getDate"
            show-time
            :allow-clear="false"
            @change="changeGetDate"
          />
        </a-form-item>
        <a-form-item label="订单号">
          <a-input
            v-model:value="params.orderId"
            placeholder="请输入"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="doQuery" perm="coupon:record:view">
            <template #icon>
              <SearchOutlined />
            </template>
            查询
          </a-button>
        </a-form-item>
      </a-form>
      <div style="padding: 4px 0">
        <a-table
          row-key="couponId"
          :data-source="dataList.list"
          :columns="columns"
          :loading="loading"
          :pagination="false"
          :scroll="{ x: 1500, y: 560 }"
          bordered
        >
        </a-table>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:page-size="pageSize"
        :total="total"
        :show-total="total => `${total}条数据`"
        @change="currentChange"
      >
      </a-pagination>
    </div>
  </a-card>
</template>

<script setup>
import { onMounted, ref } from "vue";
import useCouponRecord from "@/hooks/coupon/useCouponRecord";
import usePagination from "@/hooks/usePagination";
import CardHeader from "@/components/CardHeader/index.vue";

const {
  params,
  dataList,
  total,
  loading,
  columns,
  loadData,
  useStates,
  changeGetDate,
} = useCouponRecord();

const { page, pageSize, currentChange, doQuery } = usePagination(loadData);

const modalContainer = ref(null);

onMounted(() => {
  loadData(page.value, pageSize.value);
});
</script>
